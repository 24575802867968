import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ScaffoldModule } from "./scaffold/scaffold.module";
import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgSelectConfig } from "@ng-select/ng-select";


import { AuthModule } from "./auth/auth.module";
import { RouterModule } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorInterceptor } from "./shared/helpers/error.interceptor";
import { LoaderInterceptor } from "./shared/helpers/loader.interceptor";
import { SharedModule } from "./shared/shared.module";
import { ToastrModule } from "ngx-toastr"

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './shared/services/messaging.service';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AppRoutingModule,
    AuthModule,
    RouterModule,
    // IndexModule,
    // CmsModule,
    ScaffoldModule,
    ModalModule.forRoot(),
    // FaqModule,
    // PricingHistoryModule,
    // NotificationsModule,
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    BrowserAnimationsModule,
    SharedModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      newestOnTop: true,
      autoDismiss: true,
    }),
  ],
  providers: [
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    NgSelectConfig,
    MessagingService,
    AsyncPipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
