import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from "@angular/core";
import { Subject } from "rxjs";
import { CoreService } from "./shared/services/core.service";
import { CookieService } from "ngx-cookie-service";
import { AuthService } from "./shared/services/auth.service";
import { MessagingService } from "./shared/services/messaging.service";
import { NotificationService } from "./shared/services/notification.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  message;
  title = "adminpanel";
  // isLoading = this.coreService.isLoading$;
  isLoading = false;
  first_token = 1;
  logindata: any;
  notification_token;
  constructor(
    private coreService: CoreService,
    private messagingService: MessagingService,
    private notificationService: NotificationService,
    private cookieService: CookieService,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    
  ) {
  }
  ngOnInit(): void {
    const userId = "user001";
    this.messagingService.requestPermission(userId);
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage.subscribe(
      (response) => {
      }
    );

    this.coreService.isLoading$.subscribe((x) => {
      this.isLoading = x;
      this.cd.markForCheck();
    });

    const isData = this.cookieService.check("user");
    const accesToken = this.cookieService.check("accessToken");
    if (isData) {
      // this.logindata = JSON.parse(this.cookieService.get("user"));
      // this.coreService.currentUserSubject.next(this.logindata);
      // this.coreService.isAuthenticatedSubject.next(true);
      this.getUser();
    } else {
      this.coreService.currentUserSubject.next(null);
      this.coreService.isAuthenticatedSubject.next(false);
    }
  

  }

  getUser() {
    this.authService.getProfile().subscribe((resp) => {
      if (resp.success == 1) {
        this.logindata = resp.data.user;
        this.coreService.currentUserSubject.next(this.logindata);
        this.coreService.isAuthenticatedSubject.next(true);
        this.notificationService.bellIconList();
        this.cookieService.set("user", JSON.stringify(resp.data.user), 1);

        // this.coreService.permissionSubject.next(
        //   resp.data.user.data.permissions
        // );
        this.coreService.pageSize = resp.data.settings
          ? resp.data.settings.value
          : "10";
      }
    });
  }

  getToken() {  
    let data = {
      device_id: this.notification_token,
      device_type: "A",
    };
    this.authService.getToken(data).subscribe((response) => {
      if (response.success == 1) {
        this.cookieService.set(
          "accessToken",
          response.data.auth.type + " " + response.data.auth.token,
          1,
          "/",
          "",
          false,
          "Lax"
        );
      }
    });
  }


}
