import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { MustMatch } from "src/app/shared/validators/must-match";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from '../../shared/services/auth.service';
import { CoreService } from '../../shared/services/core.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  submitted = false;
  token;
  modalRef: BsModalRef;

  modalTitle = "Reset Password";
  modalMessage;
  modalButtonText = "Ok";

  new_password = new FormControl("", [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(20),
  ]);

  confirm_password = new FormControl("", [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(20),
    MustMatch("new_password"),
  ]);

  form = new UntypedFormGroup({
    new_password: this.new_password,
    confirm_password: this.confirm_password
  })

  constructor(
    private modalService: BsModalService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private coreService: CoreService
  ) {
    this.activatedRoute.queryParams.subscribe(query => {
      this.token = query.token;
    });
  }

  ngOnInit() {
  }

  get f() {
    return this.form.controls;
  }

  onSubmit(template) {
    this.submitted = true;
    if (this.form.valid) {
      let data = {
        token: this.token,
        password: this.new_password.value
      }
      this.authService.resetPassword(data).subscribe(response => {
        if (response.success == 1) {
          this.modalMessage = response.data.message;
          this.openModal(template);
        }
      })
    }
  }


  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'modal-sm modal-dialog-centered', keyboard : false, backdrop: true,
      ignoreBackdropClick: true })
    );
  }

  onButtonClick() {
    this.modalRef.hide();
    this.coreService.navigateTo("/");
  }
}
