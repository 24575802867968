import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from "@angular/router";
import { CoreService } from "../services/core.service";
import { CookieService } from "ngx-cookie-service";
import { Actors } from "../constants";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private coreService: CoreService,
    private cookieService: CookieService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.cookieService.check("accessToken");
    const user = this.cookieService.check("user");
    let currentuser;
    let currentUser = this.coreService.currentUser.subscribe(
      (users) => (currentuser = users)
    );
    currentUser.unsubscribe();
    if (token && user) {
      const current_user = JSON.parse(this.cookieService.get("user"));
      if (current_user.user_type == Actors.User) {
        return true;
      }
      this.router.navigate(["/"]);
      return false;
    }
    this.router.navigate(["/"]);
    return true;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const token = this.cookieService.check("accessToken");
    const user = this.cookieService.check("user");
    let currentuser;
    let currentUser = this.coreService.currentUser.subscribe(
      (users) => (currentuser = users)
    );
    currentUser.unsubscribe();

    if (token && user) {
      const current_user = JSON.parse(this.cookieService.get("user"));
      if (current_user.user_type == Actors.User) {
        return true;
      }
      this.router.navigate(["/"]);
      return false;
    }
    this.router.navigate(["/"]);
    return true;
  }
}
