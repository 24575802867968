<footer class="footer">
  <div class="inner-footer">
    <div class="container">
      <div class="row row-cols-1 row-cols-md-3">
        <div class="col">
          <div class="footer-logo mb-4">
            <img src="assets/img/logo-white.png" alt="logo" />
          </div>
          <p>40 Burma road, Apapa, Lagos, Nigeria.</p>
          <p>
            Phone:
            <a href="tel:+234 906 000 4103"> +234 906 000 4103 </a>
          </p>
          <p>
            Email:
            <a target="_blank" href="mailto:info@mobintegrated.com">
              info@mobintegrated.com
            </a>
          </p>
        </div>
        <div class="col">
          <h4 class="block-title text-white font-weight-normal mb-3">
            Quick Links
          </h4>
          <ul class="list-unstyled">
            <li><a routerLink="/cms/about-us">About Us</a></li>
            <li>
              <a routerLink="/cms/terms-conditions">Terms & Conditions</a>
            </li>
            <li><a routerLink="/cms/privacy-policy">Privacy Policy</a></li>
            <li><a routerLink="/faq">FAQ's</a></li>
            <!-- <li><a routerLink="/contact-us">Contact Us</a></li> -->
          </ul>
        </div>
        <div class="col">
          <h4 class="block-title text-white font-weight-normal mb-3 mb-md-4">
            Download App Links
          </h4>
          <a
            style="cursor: pointer"
            href="https://play.google.com/store/apps/details?id=com.mobgasuser"
            target="_blank"
            class="mb-3 d-block"
          >
            <img
              src="assets/img/play-store-white.png"
              class="mr-2"
              alt="Play Store Image"
            />
          </a>
          <a
            style="cursor: pointer"
            href="https://apps.apple.com/us/app/mob-gas/id1609400503"
            target="_blank"
          >
            <img src="assets/img/app-store-white.png" alt="App Store Image" />
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-copyright">
    <div class="container">
      <div class="row">
        <div class="col-md-6 text-center text-md-left">
          <p class="mb-2 mb-md-0">©mobgas {{ date }}. All Rights Reserved</p>
        </div>
        <!-- <div class="col-md-6 text-center text-md-right">
          <div class="social-icons">
            <a class="pointer"><i class="zmdi zmdi-hc-fw zmdi-facebook"></i></a>
            <a class="pointer"><i class="zmdi zmdi-hc-fw zmdi-instagram"></i></a>
            <a class="pointer"><i class="zmdi zmdi-hc-fw zmdi-linkedin"></i></a>
          </div>
        </div> -->
      </div>
      <a
        class="pointer whatsapp-icon"
        href="https://api.whatsapp.com/send?phone=2349060004103"
        target="_blank"
      >
        <i class="zmdi zmdi-whatsapp zmdi-hc-3x text-white"></i
      ></a>
    </div>
  </div>
</footer>
