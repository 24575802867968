import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cancel-box',
  templateUrl: './cancel-box.component.html',
  styleUrls: ['./cancel-box.component.scss']
})
export class CancelBoxComponent implements OnInit {
  @Output() onCancelOrder = new EventEmitter();
  @Output() onCloseModel = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onCancel() {
    this.onCancelOrder.emit();
  }

  onClose() {
    this.onCloseModel.emit();
  }

}
