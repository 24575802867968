import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { CommonService } from "../../services/common.service";
import {
  FormControl,
  Validators,
} from "../../../../../node_modules/@angular/forms";
import { CoreService } from "../../services/core.service";

@Component({
  selector: "app-apply-promocode-box",
  templateUrl: "./apply-promocode-box.component.html",
  styleUrls: ["./apply-promocode-box.component.scss"],
})
export class ApplyPromocodeBoxComponent implements OnInit {
  promocodeList;
  @Output() onCloseModel = new EventEmitter();
  @Output() applyedPromocode = new EventEmitter();

  promocode = new FormControl(null, [Validators.required]);
  constructor(
    private commonService: CommonService,
    private coreService: CoreService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.getPromocodes();
    });
  }

  checkPromocode() {
    let data = { promocode: this.promocode.value };
    this.commonService.checkPromocode(data).subscribe((response) => {
      if (response.success == 1) {
        this.applyPromocode(response.data.promocode);
        // this.coreService.showMessge({
        //   type: "success",
        //   message: "Promo Code applied successfully",
        // });
      }
    });
  }

  getPromocodes() {
    this.commonService.getPromocodes().subscribe((response) => {
      if (response.success == 1) {
        this.promocodeList = response.data.promocodes;
      }
    });
  }

  applyPromocode(item) {
    this.applyedPromocode.emit(item);
    this.coreService.showMessge({
      type: "success",
      message: "Promo Code applied successfully",
    });
    this.closeModel();
  }

  closeModel() {
    this.onCloseModel.emit();
  }
}
