import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { CoreService } from "./core.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(
    private apiService: ApiService,
    private coreService: CoreService
  ) {}

  list(data): Observable<any> {
    return this.apiService.post("/notifications/list", data);
  }

  unreadCount(): Observable<any> {
    return this.apiService.get("/notifications/unreadCount");
  }

  read(data): Observable<any> {
    return this.apiService.post("/notifications/read", data);
  }

  clearAll(): Observable<any> {
    return this.apiService.get("/notifications/clearAll");
  }

  bellIconList() {
    this.unreadCount().subscribe((response) => {
      if (response.success == 1) {
        let data = response.data;
        this.coreService.notificationListSubject.next({
          count: data.notification_count,
          list: data.list,
        });
      }
    });
  }
}
