import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor(private apiService: ApiService) {}

  getStates(): Observable<any> {
    return this.apiService.get("/common/states");
  }

  getLocalGovt(data): Observable<any> {
    return this.apiService.post("/common/localGovts", data);
  }

  contactUs(data): Observable<any> {
    return this.apiService.post("/common/contactUs", data);
  }

  getMakes(): Observable<any> {
    return this.apiService.get("/common/make");
  }

  cmsContent(): Observable<any> {
    return this.apiService.get("/common/cmsContent");
  }

  getFAQ(data): Observable<any> {
    return this.apiService.post("/common/faq", data);
  }

  sendOTP(data): Observable<any> {
    return this.apiService.post("/common/sendOTP", data);
  }

  verifyOTP(data): Observable<any> {
    return this.apiService.post("/common/verify", data);
  }

  getPromocodes(): Observable<any> {
    return this.apiService.get("/common/promocodes");
  }

  checkPromocode(data): Observable<any> {
    return this.apiService.post("/common/verifyPromocode", data);
  }

  depotList(): Observable<any> {
    return this.apiService.get("/depots");
  }

  bannerList(): Observable<any>{
    return this.apiService.get("/common/bannerImages")
  }

  priceHistory(data): Observable<any> {
    return this.apiService.post("/depots/price-history", data);
  }

  sendFileToS3(data): Observable<any> {
    return this.apiService.formDataPost("/common/sendFileToS3", data);
  }

  getMasterdata(data): Observable<any> {
    return this.apiService.post("/common/masterData", data);
  }

  getBanks(): Observable<any> {
    return this.apiService.get("/common/getBanks");
  }

  contactUsWeb(data){
      return this.apiService.post("/common/contactUsWeb",data)
  }
}
