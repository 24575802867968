<nav class="navbar navbar-expand-lg">
  <div class="container">
    <button
      class="navbar-toggler"
      type="button"
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseBasic"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="logo mr-auto">
      <a class="navbar-brand" routerLink="/order/list">
        <img src="assets/img/logo.png" alt="logo" />
      </a>
    </div>
    <div class="order-lg-2 d-flex align-items-center ml-lg-3">
      <div class="dropdown notification-menu pointer" dropdown>
        <a class="pointer" dropdownToggle>
          <i class="zmdi zmdi-hc-fw zmdi-notifications zmdi-hc-lg"></i>
          <span class="noti-count">{{ count }}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
          <div class="dropdown-header p-3">
            <h5 class="mb-0">Notifications</h5>
          </div>
          <div class="dropdown-body px-3 pb-3">
            <ul class="notification-list list-unstyled">
              <li
                class="py-3 border-bottom"
                [ngClass]="{ read: item?.is_read }"
                *ngFor="let item of notificationList"
                (click)="readNotification(item?.id)"
              >
                {{ item?.message }}
                <div class="date text-warning mt-2">{{ item?.timeAgo }}</div>
              </li>
            </ul>
            <a
              routerLink="/notifications"
              class="btn btn-primary-theme-outline btn-lg btn-block"
              >View All</a
            >
          </div>
        </div>
      </div>
      <div class="dropdown user-menu ml-4" dropdown>
        <a class="pointer" dropdownToggle>
          <img
            width="30"
            height="30"
            [src]="'assets/img/userImg.png'"
          />
        </a>
        <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
          <div class="dropdown-header p-3">
            <div class="media">
              <img
                class="align-self-center mr-3 rounded-circle"
                height="42px"
                width="42px"
                [src]="user?.image ? user?.image : 'assets/img/image.png'"
                alt=""
              />
              <div class="media-body">
                <h6 class="mb-1">{{ user?.business_name }}</h6>
                <span>{{ user?.dial_code }} {{ user?.mobile }}</span>
              </div>
            </div>
          </div>
          <div class="dropdown-menu-grid">
            <div class="form-row">
              <div class="col-6">
                <a routerLink="/profile" class="box">
                  <div class="d-block">
                    <i class="zmdi zmdi-hc-fw zmdi-account-box text-blue"></i>
                  </div>
                  My Account
                </a>
              </div>
              <!-- <div class="col-4">
                <a routerLink="/order/new-order" class="box">
                  <div class="d-block">
                    <i class="zmdi zmdi-hc-fw zmdi-shopping-cart text-blue"></i>
                  </div>
                  Buy Gas
                </a>
              </div> -->
              <div class="col-6">
                <a routerLink="/order/list" class="box">
                  <div class="d-block">
                    <i
                      class="zmdi zmdi-hc-fw zmdi-format-list-bulleted text-blue"
                    ></i>
                  </div>
                  My Order
                </a>
              </div>
              <div class="col-6">
                <a routerLink="/pricing-history" class="box">
                  <div class="d-block">
                    <i class="zmdi zmdi-hc-fw zmdi-chart text-blue"></i>
                  </div>
                  Pricing History
                </a>
              </div>
              <div class="col-6">
                <a class="box pointer" (click)="logOut()">
                  <div class="d-block">
                    <i class="zmdi zmdi-hc-fw zmdi-power text-blue"></i>
                  </div>
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="collapse navbar-collapse"
      id="collapseBasic"
      [collapse]="isCollapsed"
      [isAnimated]="true"
    >
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/']">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/order/new-order']">Buy Gas</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/cms/about-us']">About Us</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/faq']">FAQ's</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/contact-us']">Contact Us</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
