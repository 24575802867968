<form [formGroup]="form" (submit)="onSubmit()">
  <div class="modal-content">
    <div class="modal-header py-3">
      <h3 class="modal-title text-capitalize mb-0" id="AddToLoadTitle">
        Add To Load
      </h3>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="onCancel()"
      >
        <span aria-hidden="true" class="zmdi zmdi-close"></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-label-group has-text mb-0">
        <input
          type="text"
          class="form-control form-control-lg"
          placeholder="Enter Qty (in Metric Ton)"
          [formControl]="fill_qty"
        />
        <label>Enter Qty (in Metric Ton)</label>
        <span class="form-control-feedback">MT</span>
        <div
          *ngIf="f.fill_qty.errors"
          class="invalid-feedback custom-validation"
        >
          <p
            *ngIf="submitted && f.fill_qty.errors.required"
            class="help text-danger"
          >
            Please enter QTY.
          </p>
          <p *ngIf="f.fill_qty.errors.max" class="help text-danger">
            {{ dynamicValidationError }}
          </p>
          <p *ngIf="f.fill_qty.errors.min" class="help text-danger">
            Please enter qty is more then 0.
          </p>
          <p *ngIf="f.fill_qty.errors.pattern" class="help text-danger">
            Please enter numeric value only.
          </p>
        </div>
        <span class="d-block text-muted mt-2"
          >Remaining Qty:
          <span class="font-weight-bold text-default">{{
            orderQTY - fill_qty.value | number: '1.2-4'
          }}</span></span
        >
      </div>
    </div>
    <div class="modal-footer py-3">
      <button
        type="submit"
        class="btn btn-primary-theme btn-lg w-150"
        [disabled]="!form.valid"
      >
        Submit
      </button>
    </div>
  </div>
</form>
