import { Component, OnInit, TemplateRef,ChangeDetectorRef } from "@angular/core";
import { FormControl, Validators, UntypedFormGroup } from "@angular/forms";
import { CoreService } from "src/app/shared/services/core.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { AuthService } from "../../shared/services/auth.service";
import { CommonService } from "../../shared/services/common.service";
import { Router,ActivatedRoute } from "@angular/router";
import Swal from "sweetalert2";
import { saveAs } from 'file-saver';
@Component({
  selector: "app-complete-profile",
  templateUrl: "./complete-profile.component.html",
  styleUrls: ["./complete-profile.component.scss"],
})
export class CompleteProfileComponent implements OnInit {
  submitted = false;
  id:any;
  modalTitle;
  modalMessage;
  modalButtonText = "Ok";
  modalRef: BsModalRef;
  states: Array<any> = [];
  localGovt: Array<any> = [];

  urls: any;
  reviewImage: String;
  registrationUrl:any; 
  changeId = null
  registrationImage:any;
  insuranceUrl:any;
  insuranceImage:any;
  token:any;
  file_url
  registration_doc_url
  insurance_certificate_url

  urlRegPdf: any = /\.(pdf)$/;
  urlRegImage: any = /\.(jpeg|jpg|png)$/;

  profileImage = new FormControl("", []);
  registrationDoc = new FormControl("", []);
  insuranceCertificate = new FormControl("", []);
  business_location = new FormControl(null, [Validators.required]);
  business_lat = new FormControl(null, [Validators.required]);
  business_long = new FormControl(null, [Validators.required]);
  dial_code = new FormControl("+234");
  position = new FormControl(null, [Validators.required]);
  city = new FormControl(null, [Validators.required]);
  state_id = new FormControl(null, [Validators.required]);
  local_govt_id = new FormControl(null, [Validators.required]);

  form = new UntypedFormGroup({
    business_location: this.business_location,
    business_lat: this.business_lat,
    business_long: this.business_long,
    dial_code: this.dial_code,
    position: this.position,
    city: this.city,
    state_id: this.state_id,
    local_govt_id: this.local_govt_id,
  });
  userEmail:any;
  constructor(
    private modalService: BsModalService,
    private coreService: CoreService,
    private authService: AuthService,
    private commonService: CommonService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
  ) {
    let currentuser;
     this.coreService.currentUser.subscribe((users:any) => {
      if (users.id) {
          this.userEmail = users.email
          this.id = users.id
      }
    });

    this.activatedRoute.queryParams.subscribe(query => {
      this.token = query;
    });
    
  }

  ngOnInit() {
    this.getStates();
    this.getProfile();
  }

  getProfile() {
    this.authService.getProfile().subscribe((response) => {
      if (response.success == 1) {
        let user = response.data.user;
        this.form.patchValue({
          business_location: user.business_location,
          email: user.email,
          business_lat: user.latitude,
          business_long: user.longitude,
          dial_code: user.dial_code,
          position: user.position,
          city: user.city,
          state_id: user.state_id,
          local_govt_id: user.local_govt_id,
          contact_person_name: user.name,
        });
        this.getLocalGovt(user.state_id);
        this.coreService.currentUserSubject.next(user);
        this.local_govt_id.setValue(user.local_govt_id);
        this.urls = user.file;    
        this.registrationUrl = user.registration_doc;
        this.insuranceUrl = user.insurance_certificate;
        this.file_url = user.file;
        this.registration_doc_url = user.registration_doc;
        this.insurance_certificate_url = user.insurance_certificate;
        if (
          this.urls != null ||
          this.registrationUrl != null ||
          this.insuranceUrl != null
        ) {
          this.reviewImage = user.fileName;
          this.registrationImage = user.registration_doc_name;
          this.insuranceImage = user.insurance_certificate_name;
        } else {
          this.reviewImage = null;
          this.registrationImage = null;
          this.insuranceImage = null;
        }
        // var changed = angular.runChangeDetection();
        // if (changed) {
        //     angular.reRenderUIPart();
        // }
      }
    });
  }

  get f() {
    return this.form.controls;
  }

  onSubmit(template) {
    this.submitted = true;
    let state_id = this.state_id.value.toString();
    let local_govt_id = this.local_govt_id.value.toString();
    this.local_govt_id.setValue(local_govt_id);
    this.state_id.setValue(state_id);

    if (!this.business_lat.valid && !this.business_long.valid) {
      this.coreService.showMessge({
        type: "danger",
        message: "Please select street name from place picker.",
      });
    }
    if (this.form.valid) {
      let data = { ...this.form.value };
      this.authService.editProfile(data).subscribe(async (response) => {
        if (response.success == 1) {
          this.modalTitle = "Complete Profile";
          this.modalMessage = response.data.message;
          this.modalButtonText = "Ok";
          this.openModal(template);
          this.submitted = false;
          this.form.reset();
        }
      });
    }
    
  }

  documentUpdate(file) {
    const documentData:any = {
      id: this.id,
    };
    if( this.changeId == 1){
      documentData.file = file;
    }if( this.changeId == 2){
      documentData.registration_doc = file
    }if( this.changeId == 3){
      documentData.insurance_certificate = file
    }
    this.authService.documentUpload(documentData).subscribe((resp) => {
      if (resp.success == 1) {
        const message = {
          message: resp.data.message,
          type: "success",
        };
        this.coreService.showMessge(message);
      }
    });
  }

  getStates() {
    this.commonService.getStates().subscribe((response) => {
      if (response.success == 1) {
        this.states = response.data.states;
      }
    });
  }

  getLocalGovt(id) {
    let data = { state_id: id };
    this.local_govt_id.reset();
    this.commonService.getLocalGovt(data).subscribe((response) => {
      if (response.success == 1) {
        this.localGovt = response.data.local_govts;
      }
    });
  }

  handleAddressChange(address) {
    if (address) {
      this.business_location.setValue(address.formatted_address);
      this.business_lat.setValue(address.geometry.location.lat().toString());
      this.business_long.setValue(address.geometry.location.lng().toString());
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign(
        {},
        {
          class: "modal-sm modal-dialog-centered",
          keyboard: false,
          backdrop: true,
          ignoreBackdropClick: true,
        }
      )
    );
  }

  onButtonClick() {
    this.modalRef.hide();
    if (this.userEmail == localStorage.getItem('email') && localStorage.getItem("order")) {
      this.router.navigate(["/order/review-order"], { queryParams:{tabId:1}});
    }else if(this.token && this.token.newUser == 2){
      this.router.navigate(["/order/new-order"], { queryParams:this.token });
    }
    else{
      this.router.navigate(["/order/list"]);
    }
  }

  changeFile(event, id) {
    if (event.target.files.length > 0) {
      this.changeId = id;
      let reg = /(.*?)\.(jpg|jpeg|png|pdf)$/;
      const current_file = event.target.files[0];
      if (current_file["name"].match(reg)) {
        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            if (this.changeId== 1) {
              this.urls = event.target.result;
              this.profileImage.setValue(current_file);
              this.reviewImage = current_file.name;
              this.fileToS3Server(current_file);
            } else if (this.changeId == 2) {
              this.registrationDoc.setValue(current_file);
              this.registrationUrl = event.target.result;
              this.registrationImage = current_file.name;
              this.fileToS3Server(current_file);
            } else if (this.changeId == 3) {
              this.insuranceCertificate.setValue(current_file);
              this.insuranceUrl = event.target.result;
              this.insuranceImage = current_file.name;
              this.fileToS3Server(current_file);
            }
            this.cdr.markForCheck();
          };
          reader.readAsDataURL(event.target.files[i]);
        }
      } else {
        Swal.fire("Invalid File!", "Please select valid file ", "error");
        return false;
      }
    }
  }


  fileToS3Server(data) {
    const formData = new FormData();
    formData.append("image", data);
    this.commonService.sendFileToS3(formData).subscribe(async(response) => {
      if (response.success) {
        if(this.changeId == 1){
          this.urls = await response.data.image_url;
          const reviewImage = await response.data.image_url.split('/');
          this.reviewImage = reviewImage[reviewImage.length-1];
          this.documentUpdate(this.urls);
        }else if(this.changeId == 2){
          this.registrationUrl =await response.data.image_url
          const registrationImage = await  response.data.image_url.split('/');
          this.registrationImage = registrationImage[registrationImage.length-1];
          this.documentUpdate(this.registrationUrl);
        }else if(this.changeId == 3){
          this.insuranceUrl = await response.data.image_url
          const insuranceImage =await response.data.image_url.split('/');
          this.insuranceImage = insuranceImage[insuranceImage.length-1];
          this.documentUpdate(this.insuranceUrl);
        }
        
      }
    });
  }

  downloadDocument(data) {
    window.open(data)
  }

  removeImage(id) {
    const changeId = id;
    if (changeId == 1) {
      this.changeId = 1
      this.profileImage.reset();
      this.urls = null;
      this.reviewImage = "";
      this.file_url = 1;
      this.documentUpdate(this.reviewImage);
    }
    if (changeId == 2) {
      this.changeId = 2
      this.registrationDoc.reset();
      this.registrationUrl = null;
      this.registrationImage = "";
      this.registration_doc_url = 1;
      this.documentUpdate(this.registrationImage);
    }
    if (changeId == 3) {
      this.changeId =3
      this.insuranceCertificate.reset();
      this.insuranceUrl = null;
      this.insuranceImage = "";
      this.insurance_certificate_url = 1;
      this.documentUpdate(this.insuranceImage);
    }
  }

}
