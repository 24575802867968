<div class="content">
    <div class="section section-padding">
        <div class="container">
            <div class="auth-box">
                <div class="section-title text-center mb-4 mb-md-5">
                    <h1 class="heading mb-3 h3">Reset Password</h1>
                    <p class="text-muted font-size-18">Reset Password Here</p>
                </div>
                <form [formGroup]="form" (submit)="onSubmit(template)">
                    <div class="form-label-group">
                        <input type="password" class="form-control form-control-lg" placeholder="New Password" [formControl]="new_password">
                        <label>New Password</label>
                        <div *ngIf="f.new_password.errors" class="invalid-feedback custom-validation">
                            <p *ngIf="submitted && f.new_password.errors.required" class="help text-danger">
                                Please enter new password.
                            </p>
                            <p *ngIf="f.new_password.errors.maxlength" class="help text-danger">
                                Password contain maximum 20 Characters.
                            </p>
                            <p *ngIf="f.new_password.errors.minlength" class="help text-danger">
                                Password contain minimum 6 Characters
                            </p>
                        </div>
                    </div>
                    <div class="form-label-group">
                        <input type="password" class="form-control form-control-lg" placeholder="Confirm Password" [formControl]="confirm_password">
                        <label>Confirm Password</label>
                        <div *ngIf="f.confirm_password.errors" class="invalid-feedback custom-validation">
                            <p *ngIf="submitted && f.confirm_password.errors.required" class="help text-danger">
                                Retype new password.
                            </p>
                            <p *ngIf="f.confirm_password.errors.validatePassword" class="help text-danger">
                                Your password is not matched
                            </p>
                            <p *ngIf="f.confirm_password.errors.maxlength" class="help text-danger">
                                Password contain maximum 20 Characters.
                            </p>
                            <p *ngIf="f.confirm_password.errors.minlength" class="help text-danger">
                                Password contain minimum 6 Characters
                            </p>
                            <p *ngIf="f.confirm_password.errors.notMatch" class="help text-danger">
                                New password and confirm password should be same.
                            </p>
                        </div>
                    </div>
                    <div class="form-action text-right mt-4">
                        <button type="submit" class="btn btn-primary-theme btn-lg btn-block">Done</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Thank You Account Created Modal -->
<ng-template #template>
    <app-message-box [title]="modalTitle" [message]="modalMessage" [buttonText]="modalButtonText" (buttonClicked)="onButtonClick()"></app-message-box>
</ng-template>