import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private apiService: ApiService) { }

    getToken(data): Observable<any> {
        return this.apiService.post("/auth/getToken", data);
    }

    register(data): Observable<any> {
        return this.apiService.post("/auth/register", data);
    }

    login(data): Observable<any> {
        return this.apiService.post("/auth/login", data);
    }

    logout(): Observable<any> {
        return this.apiService.get("/auth/logout");
    }

    forgotPassword(data): Observable<any> {
        return this.apiService.post("/auth/forgotPassword", data);
    }

    resetPassword(data): Observable<any> {
        return this.apiService.post("/auth/resetPassword", data);
    }

    changePassword(data): Observable<any> {
        return this.apiService.post("/auth/changePassword", data);
    }

    editProfile(data): Observable<any> {
        return this.apiService.post("/auth/editProfile", data);
    }

    getProfile(): Observable<any> {
        return this.apiService.get("/auth/profile");
    }
    
    documentUpload(data):Observable<any>{
        return this.apiService.formDataPost("/customer/addDocumentUpload",data);
    }

    deleteAccount(data?): Observable<any>{
        return this.apiService.post("/auth/deleteUserAccount",data)
    }

}