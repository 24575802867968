import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./shared/guards/auth-guard";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./index/index.module").then((m) => m.IndexModule),
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./profile/profile.module").then((m) => m.ProfileModule),
    canActivateChild: [AuthGuard],
  },
  {
    path: "order",
    loadChildren: () =>
      import("./orders/orders.module").then((m) => m.OrdersModule),
    canActivateChild: [],
  },
  {
    path: "faq",
    loadChildren: () => import("./faq/faq.module").then((m) => m.FaqModule),
    // canActivateChild: [AuthGuard],
  },
  {
    path: "contact-us",
    loadChildren: () =>
      import("./contact-us/contact-us.module").then((m) => m.ContactUsModule),
    canActivateChild: [AuthGuard],
  },
  {
    path: "pricing-history",
    loadChildren: () =>
      import("./pricing-history/pricing-history.module").then(
        (m) => m.PricingHistoryModule
      ),
    canActivateChild: [AuthGuard],
  },
  {
    path: "notifications",
    loadChildren: () =>
      import("./notifications/notifications.module").then(
        (m) => m.NotificationsModule
      ),
    canActivateChild: [AuthGuard],
  },
  {
    path: "cms",
    loadChildren: () => import("./cms/cms.module").then((m) => m.CmsModule),
  },
  {
    path: "account",
    loadChildren: () => import("./account/delete-account.module").then((m) => m.DeleteAccountModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
