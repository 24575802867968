import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { CoreService } from "../services/core.service";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(public coreServiceService: CoreService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.coreServiceService.showLoading();
    return next.handle(req).pipe(
      finalize(() =>
        setTimeout(() => {
          this.coreServiceService.hideLoading();
        }, 100)
      )
    );
  }
}
