import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MainTemplateComponent } from "./container/main-template/main-template.component";
import { SubTemplateComponent } from "./container/sub-template/sub-template.component";
import { SubHeaderComponent } from "./components/sub-header/sub-header.component";
import { MainHeaderComponent } from "./components/main-header/main-header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { RouterModule } from "@angular/router";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';

@NgModule({
  declarations: [
    MainTemplateComponent,
    SubTemplateComponent,
    SubHeaderComponent,
    MainHeaderComponent,
    FooterComponent,
  ],
  imports: [CommonModule, RouterModule, BsDropdownModule.forRoot(), CollapseModule.forRoot(),],
  // exports: [MainHeaderComponent, SubHeaderComponent],
})
export class ScaffoldModule {}
