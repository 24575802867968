import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss']
})
export class SubHeaderComponent implements OnInit {
  isCollapsed = true;
  apiUrlVendor=environment.apiUrlVendor;
  constructor() { }

  ngOnInit() {
  }

}
