import { Pipe, PipeTransform } from "@angular/core";
import { PendingOrderStatus } from "../constants";

@Pipe({
  name: "orderStatus",
})
export class OrderStatusPipe implements PipeTransform {
  transform(value: any): any {
    switch (value) {
      case PendingOrderStatus.AwaitingPaymentConfirmation:
        return "Awaiting Payment Confirmation";
        break;
      case PendingOrderStatus.PaymentConfirmed:
        return "Payment Confirmed";
        break;
      case PendingOrderStatus.TruckNotimation:
        return "Truck Nominations";
        break;
      case PendingOrderStatus.Released:
        return "Released";
        break;
      case PendingOrderStatus.Cancelled:
        return "Cancelled";
        break;
      case PendingOrderStatus.Refunded:
        return "Refunded";
        break;
      case PendingOrderStatus.Completed:
        return "Completed";
        break;
    }
    return null;
  }
}
