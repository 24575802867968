<div class="content">
    <div class="section section-padding">
        <div class="container">
            <div class="auth-box">
                <div class="section-title text-center mb-4 mb-md-5">
                    <h1 class="heading mb-3 h3">Forgot Password</h1>
                    <p class="text-muted font-size-18">Enter the registered email address to reset your password.
                    </p>
                </div>
                <form [formGroup]="form" (submit)="onSubmit(template)">
                    <div class="form-label-group">
                        <input type="email" class="form-control form-control-lg" placeholder="Email" [formControl]="email">
                        <label>Email</label>
                        <div *ngIf="f.email.errors" class="invalid-feedback custom-validation">
                            <p *ngIf="submitted && f.email.errors.required" class="help text-danger">
                                Please enter Email Id.
                            </p>
                            <p *ngIf="f.email.errors.email" class="help text-danger">
                                Please enter valid email.
                            </p>
                        </div>
                    </div>
                    <div class="form-action text-right mt-4">
                        <button type="submit" class="btn btn-primary-theme btn-lg btn-block">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<ng-template #template>
    <app-message-box [title]="modalTitle" [message]="modalMessage" [buttonText]="modalButtonText" (buttonClicked)="onButtonClick()"></app-message-box>
</ng-template>