import { Component, OnInit, TemplateRef } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { CoreService } from "src/app/shared/services/core.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { UntypedFormGroup, FormControl, Validators } from "@angular/forms";
import { CookieService } from "ngx-cookie-service";
import { Router,ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.scss"],
})
export class SignInComponent implements OnInit {
  submitted = false;
  modalRef: BsModalRef;
  notification_token;

  is_show_password = false;
  password_type = "password";

  modalTitle = "Welcome";
  modalMessage = "Login";
  modalButton1Text = "Yes";
  modalButton2Text = "No";
  token = null;
  device_id = new FormControl(null);
  device_type = new FormControl("A");
  email = new FormControl(null, [Validators.required, Validators.email]);
  password = new FormControl(null, [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(20),
  ]);
  form = new UntypedFormGroup({
    device_id: this.device_id,
    device_type: this.device_type,
    email: this.email,
    password: this.password,
  });

  constructor(
    private modalService: BsModalService,
    private coreService: CoreService,
    private authService: AuthService,
    private cookieService: CookieService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    
  ) { 
    this.activatedRoute.queryParams.subscribe(query => {
      this.token = query;
    });
    this.removeCokies()}

  ngOnInit() {
    this.notification_token = this.cookieService.get("notification_token");
    this.getToken();
  }

  get f() {
    return this.form.controls;
  }

  removeCokies() {
    sessionStorage.clear();
    this.cookieService.deleteAll()
  }

  getToken() {
    let data = {
      device_id: this.notification_token,
      device_type: "A",
    };
    this.authService.getToken(data).subscribe((response) => {
      if (response.success == 1) {
        this.cookieService.set(
          "accessToken",
          response.data.auth.type + " " + response.data.auth.token,
          1,
          "/",
          "",
          false,
          "Lax"
        );
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    this.device_id.setValue(this.notification_token);
    if (this.form.valid) {
      let data = { ...this.form.value };
      this.authService.login(data).subscribe((response) => {
        if (response.success == 1) {
          this.cookieService.set(           
            "user",
            JSON.stringify(response.data.user),
            1,
            "/",
            "",
            false,
            "Lax"
          );
          this.coreService.currentUserSubject.next(response.data.user);
          this.coreService.isAuthenticatedSubject.next(true);
         ; 
         if(this.token.tabId == 1){
          //  && this.email.value == localStorage.getItem('email')
          this.router.navigate(["/order/review-order"], { queryParams:{tabId:1}});
         }else if(this.token.tabId == 2 && !this.token.newUser ) {
          this.router.navigate(["/order/new-order"], { queryParams:this.token });
         }else{
          if (!response.data.user.is_profile_completed) {
            this.router.navigate(["/complete-profile"], { queryParams:this.token ? this.token : ''  });
            // this.coreService.navigateTo("/complete-profile");
          } else {
            this.coreService.navigateTo("/order/list");
          }
         }
        }
      });
    }
  }

  onclisckShowPassword() {
    this.is_show_password = !this.is_show_password;
    if (this.is_show_password) {
      this.password_type = "text";
    } else {
      this.password_type = "password";
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  onButton1Click() {
    this.modalRef.hide();
    // this.coreService.navigateTo(/");
  }

  onButton2Click() {
    this.modalRef.hide();
    // this.coreService.navigateTo("/complete-profile");
  }
}
