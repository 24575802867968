import { Component, OnInit, TemplateRef } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { CoreService } from "src/app/shared/services/core.service";
import { AuthService } from "../../shared/services/auth.service";
import { FormControl, Validators, UntypedFormGroup } from "@angular/forms";
import { CookieService } from "ngx-cookie-service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"],
})
export class SignUpComponent implements OnInit {
  submitted = false;
  modalRef: BsModalRef;
  notification_token;

  modalTitle = "Thank You";
  modalMessage =
    "Your account has been created. Please check your email to activate account.";
  modalButtonText = "Ok";

  is_show_password = false;
  password_type = "password";

  email = new FormControl(null, [Validators.required, Validators.email]);
  password = new FormControl(null, [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(20),
  ]);
  business_name = new FormControl(null, [Validators.required]);
  name = new FormControl(null, [Validators.required]);
  business_location = new FormControl(null);
  business_lat = new FormControl(null);
  business_long = new FormControl(null);
  dial_code = new FormControl("+234");
  mobile = new FormControl(null, [
    Validators.required,
    Validators.minLength(10),
    Validators.maxLength(10),
  ]);
  token: any = {};

  form = new UntypedFormGroup({
    email: this.email,
    password: this.password,
    business_name: this.business_name,
    name: this.name,
    business_location: this.business_location,
    business_lat: this.business_lat,
    business_long: this.business_long,
    dial_code: this.dial_code,
    mobile: this.mobile,
  });

  get f() {
    return this.form.controls;
  }

  constructor(
    private modalService: BsModalService,
    private coreService: CoreService,
    private authService: AuthService,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.removeCokies();
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((query) => {
      if (query.tabId == 2) {
        this.token = {...query};
        this.token.newUser = 2;
      }
    });
    this.notification_token = this.cookieService.get("notification_token");
    this.getToken();
  }

  removeCokies() {
    sessionStorage.clear();
    this.cookieService.deleteAll();
  }

  getToken() {
    let data = {
      device_id: this.notification_token,
      device_type: "A",
    };
    this.authService.getToken(data).subscribe((response) => {
      if (response.success == 1) {
        this.cookieService.set(
          "accessToken",
          response.data.auth.type + " " + response.data.auth.token,
          1,
          "/",
          "",
          false,
          "Lax"
        );
      }
    });
  }

  onclisckShowPassword() {
    this.is_show_password = !this.is_show_password;
    if (this.is_show_password) {
      this.password_type = "text";
    } else {
      this.password_type = "password";
    }
  }

  onSubmit(template) {
    this.submitted = true;
    if (this.form.valid) {
      let data = {
        ...this.form.value,
        device_id: this.cookieService.get("notification_token"),
        device_type: "A",
      };
      this.authService.register(data).subscribe((response) => {
        if (response.success == 1) {
          localStorage.setItem("email", this.email.value);
          this.openModal(template);
          this.submitted = false;
          this.form.reset();
        }
      });
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign(
        {},
        {
          class: "modal-sm modal-dialog-centered",
          keyboard: false,
          backdrop: true,
          ignoreBackdropClick: true,
        }
      )
    );
  }

  onButtonClick() {
    this.modalRef.hide();
    // this.coreService.navigateTo("/auth/sign-in",this.token);
    this.router.navigate(["/auth/sign-in"], {
      queryParams: this.token ? this.token : "",
    });
  }

  onlyNumberKey(event) {
    return event.charCode === 8 || event.charCode === 0
      ? null
      : event.charCode >= 48 && event.charCode <= 57;
  }
}
