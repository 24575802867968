<nav class="navbar navbar-expand-lg">
  <div class="container">
    <button
      class="navbar-toggler"
      type="button"
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseBasic"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="logo mr-auto">
      <a class="navbar-brand" routerLink="/"
        ><img src="assets/img/logo.png" alt="logo"
      /></a>
    </div>
    <div class="button-right order-lg-2 ml-lg-3">
      <!-- <a
        routerLink="/auth/sign-in"
        class="btn btn-primary-theme d-none d-lg-inline mr-4"
        >Customer
      </a>
      <a
        href=" https://vendor.mobgas.co/"
        class="btn btn-primary-theme-outline d-none d-lg-inline"
        target="_blank"
        >Vendor
      </a> -->
      <div class="d-none d-lg-inline">
        <div class="d-flex flex-column font-size-14">
          <label class="mb-1 text-muted font-size-12 font-weight-light d-block">Existing Users : </label>
          <div class="d-flex align-items-center">
            <a routerLink="/auth/sign-in" class="nav-link p-0 font-weight-medium text-uppercase text-primary">Customer </a>
            <span class="mx-2 text-muted mt-n-4">|</span>
            <a  href={{apiUrlVendor}} target="_blank" class="nav-link p-0 font-weight-medium text-uppercase text-primary">Vendor</a>
          </div>
        </div>
      </div>
      <!-- <div class="dropdown d-none d-lg-inline" dropdown>
        <a class="pointer btn btn-primary-theme" dropdownToggle>
          Existing Users
        </a>
        <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu>
          <a routerLink="/auth/sign-in"
            class="dropdown-item"
            >Customer</a
          >
          <a
          href={{apiUrlStaging}}
            class="dropdown-item"
            target="_blank"
            >Vendor</a
          >
        </ul>
      </div> -->
      <a routerLink="/auth/sign-up" class="d-inline d-lg-none"
        ><i class="zmdi zmdi-hc-fw zmdi-account zmdi-hc-2x text-dark"></i
      ></a>
    </div>
    <div
      class="collapse navbar-collapse"
      id="collapseBasic"
      [collapse]="isCollapsed"
      [isAnimated]="true"
    >
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a
            class="nav-link"
           routerLink="/"
            >Home</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
           routerLink="/cms/about-us"
            >About Us</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/order/new-order">Buy Gas</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/faq">FAQ's</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#contact-us">Contact Us</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"  routerLink="/auth/sign-up">Sign Up</a>
        </li>
        <li class="nav-item d-block d-lg-none my-2 my-lg-0">
          <div class="d-flex flex-column font-size-14">
            <label class="mb-1 text-muted font-size-12 font-weight-light d-block">Existing Users : </label>
            <div class="d-flex align-items-center">
              <a routerLink="/auth/sign-in" class="nav-link p-0 font-weight-medium text-uppercase text-primary">Customer</a>
              <span class="mx-2 text-muted mt-n-4">|</span>
              <a href={{apiUrlVendor}} target="_blank" class="nav-link p-0 font-weight-medium text-uppercase text-primary">Vendor</a>
            </div>
          </div>
          <!-- <div class="dropdown" dropdown>
            <a class="pointer btn btn-primary-theme" dropdownToggle>
              Existing Users
            </a>
            <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu>
              <a routerLink="/auth/sign-in"
                class="dropdown-item"
                >Customer</a
              >
              <a
              href= {{apiUrlStaging}}
                class="dropdown-item"
                target="_blank"
                >Vendor</a
              >
            </ul>
          </div> -->
          <!-- https://vendor.mobgas.co/" -->
        </li>
        <!-- <li class="nav-item d-block d-lg-none">
          <a class="btn btn-primary-theme" routerLink="/auth/sign-in"
            >Existing Customer
          </a>
        </li>
        
        <li class="nav-item d-block d-lg-none mt-3 mt-lg-0">
          <a
            class="btn btn-primary-theme-outline"
            routerLink="https://vendor.mobgas.co/"
            target="_blank"
            >Existing Vendor
          </a>
        </li> -->
        <!-- <li class="nav-item">
          <a class="nav-link" routerLink="/auth/sign-in">Sign In</a>
        </li> -->
      </ul>
    </div>
  </div>
</nav>
